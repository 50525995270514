"use client"

import React, { useEffect, useRef } from "react"
import { Heading, Wrapper } from "./common"

const Testimonials = () => {
	const scriptRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const script = document.createElement("script")
		script.src = "https://static.senja.io/dist/platform.js"
		script.async = true

		if (scriptRef.current) scriptRef.current.appendChild(script)

		return () => {
			if (scriptRef.current) {
				scriptRef.current.removeChild(script)
			}
		}
	}, [])

	return (
		<Wrapper>
			<Heading text="Testimonials" />
			<div
				className="senja-embed"
				data-id="f8bcde6e-a224-457d-abdc-8a62c54b4c81"
				data-lazyload="false"
				style={{ paddingTop: "50px" }}></div>
			<div ref={scriptRef}></div>
		</Wrapper>
	)
}

export default Testimonials
