import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/ButtonIcon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/ButtonIcon2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/ButtonIcon3.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section2Img1.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section2Img2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section2Img3.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section3Img1.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section3Img2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section3Img3.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section3Img4.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section3Img5.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section4Img1.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svgs/landing-page/Section4Img2.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/client/components/molecules/landing-page/tab-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/client/components/molecules/landing-page/testimonials.tsx");
