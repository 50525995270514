import { cn } from "@/client/lib/utils"

const Heading = ({ text }: { text: string }) => (
	<p className="font-semibold text-4xl leading-[60px] text-center text-neutral-500">
		{text}
	</p>
)
const SubHeading = ({ text }: { text: string }) => (
	<p className="mt-4 font-normal text-base leading-6 text-center text-neutral-300">
		{text}
	</p>
)

type TabSelectorProps = {
	tabs: string[]
	selected: number
	setSelected: (index: number) => void
}
const TabSelector = ({ tabs, selected, setSelected }: TabSelectorProps) => {
	return (
		<div className="flex flex-row gap-x-2 p-2 rounded-lg border border-zinc-100 w-fit mx-auto">
			{tabs.map((tab, idx) => (
				<div
					className={cn(
						"text-neutral-300 px-5 py-2 rounded-lg hover:cursor-pointer hover:opacity-80 font-semibold",
						selected === idx && "bg-brown-50",
						selected === idx && "text-primary"
					)}
					onClick={() => setSelected(idx)}
					key={idx}>
					{tab}
				</div>
			))}
		</div>
	)
}

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {}

const Wrapper = (props: WrapperProps) => {
	return (
		<div
			className={cn(
				"w-[90vw] md:w-[80vw] lg:w-[70vw] mx-auto",
				props.className
			)}
			{...props}>
			{props.children}
		</div>
	)
}
export { Heading, SubHeading, TabSelector, Wrapper }
