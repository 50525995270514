"use client"

import { useState } from "react"
import { TabSelector, Wrapper } from "./common"
import Image from "next/image"

const tabs = ["Chat with Filings", "Access Summaries", "Monitor Disclosures"]
const imagesSrc = [
	"https://res.cloudinary.com/de0gklmu0/image/upload/v1714498514/stockinsights.ai/chat_with_filings_qmeh2i.png",
	"https://res.cloudinary.com/de0gklmu0/image/upload/v1714498513/stockinsights.ai/access_summaries_u7d9c5.png",
	"https://res.cloudinary.com/de0gklmu0/image/upload/v1714498515/stockinsights.ai/monitor_disclosures_drq0ey.png",
]

const TabSection = () => {
	const [tabSelected, setTabSelected] = useState(0)
	return (
		<Wrapper>
			<TabSelector
				tabs={tabs}
				selected={tabSelected}
				setSelected={setTabSelected}
			/>
			<div className="relative w-full h-[400px] mt-10">
				<Image
					src={imagesSrc[tabSelected]}
					alt="tab-image"
					className="object-contain"
					priority={true}
					fill
				/>
			</div>
		</Wrapper>
	)
}
export default TabSection
